@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
}

.input {
  [data-qa='TextInput'] {
    pointer-events: none;
    user-select: none;
  }
}

.inputLabels {
  padding-inline: 16px;
  margin-top: 7px;
}
