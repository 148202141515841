@import 'src/styles/variables';

.page_header_branding_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.withBanner {
  height: 270px;
}

.location {
  max-width: 95%;
}

.locationWithBanner {
  margin-top: 40px;
}

.sponsor_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  flex: 1;
}

.location_container {
  max-width: 515px;
}

.hidden_heading_section {
  visibility: hidden;
  height: 0;
}

.specialTitle {
  display: none;
  font-family: var(--font-aeroport);
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  color: var(--color-D100);

  @media screen and (min-width: $breakpoint-desktop) {
    display: block;
  }
}

.star {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.text {
  color: var(--color-D60);
  font-feature-settings:
    'clig' off,
    'liga' off;
  padding-top: 15px;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.heading {
  padding: 16px;
  text-align: center;
}
