.container {
  display: flex;
  flex-direction: column;

  border: 2px solid transparent;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-direction: column;

  background-color: var(--color-L100);
  border-radius: 16px;

  padding: 24px 16px;
  gap: 24px;
}

.withOfferOrForm {
  border-color: var(--color-G100);
  border-radius: 22px 22px 16px 16px;
}

.withOfferOrFormContent {
  border-radius: 0 0 16px 16px;
}

.withOfferOrFormText {
  border-radius: 20px 20px 0 0;
  padding: 8px 16px;

  background-color: var(--color-B100);
  color: var(--color-L100);
  background-color: var(--color-G100);
}
