@import 'src/styles/variables';

.wrapper {
  background-color: var(--color-L100);
  padding: 20px 16px;
  margin: 16px 16px 8px;
  border-radius: 16px;

  gap: 16px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-desktop) {
    margin: 20px 0 0 0;
    padding: 16px;
  }
}

.filters {
  display: flex;
  flex-direction: column;

  gap: 16px;

  @media screen and (min-width: $breakpoint-desktop) {
    flex-direction: row;

    gap: 2px;
  }
}

.sumField {
  height: 76px;

  @media screen and (min-width: $breakpoint-desktop) {
    flex: 2;

    & > div {
      div,
      & {
        border-radius: 0;
      }
    }

    &:first-of-type {
      div {
        div {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
      }
    }

    &:last-of-type {
      div {
        div {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
}

.button {
  width: 100%;
  margin-top: 8px;
  @media screen and (min-width: $breakpoint-desktop) {
    max-width: 239px;
    flex: 1;
    margin-top: 0;
    margin-left: 13px;
  }
}
