@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  margin-top: 32px;
  padding: 0 16px;

  @media screen and (max-width: $breakpoint-small-mobile) {
    padding: 0 8px;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 52px;
  }
}

.layout {
  @include max-content-width;

  padding: 0;
}

.utm {
  margin-bottom: 30px;
}

.prefooter {
  @media screen and (min-width: $breakpoint-desktop) {
    margin: 0 -30px;
  }
    [data-theme='guinness'] & {
        list-style-type: none;
    }
}

.prefooter li::before {
    [data-theme='guinness'] & {
        content: "•";
        color: white;
        margin-right: 0.5em;
    }
}

.pixel {
  img {
    width: 0 !important;
  }
}

.subHeading {
    padding-bottom: 16px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-D60);
}

.table td {
    a[href^="tel"] {
        color: inherit;
        text-decoration: none;
        pointer-events: none;
    }
}

.linksContainer {
    margin-bottom: 16px;
}
