@import 'src/styles/variables';

.tagContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.badge {
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 32px;
  background: var(--color-L100);
  border: 1px solid var(--color-D10);
  height: 44px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &.active {
    border-color: var(--color-B30);
    background-color: var(--color-B06);
    color: var(--color-B100);
  }

  &.inactive {
    background: var(--color-D10);
  }
}
