.container {
    width: fit-content;
    gap: 6px;

}

.alert {
    border: 1px solid var(--color-G100) !important;
    background-color: var(--color-G06);
}

.symbol {
    color: var(--color-G100);
}

.icon {
    height: 20px !important;
    width: 20px !important;
    padding: 0 !important;
}

.withButtonIcon {
    margin-right: 6px;
}