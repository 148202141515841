@import 'src/styles/variables';

.logo {
  margin-left: 4px;
}

.buttonFullWidth {
  width: 100%;
  flex: 1;

  @media screen and (max-width: $breakpoint-desktop) {
    flex: none;
  }
}
