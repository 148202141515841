.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  max-width: 230px;
  margin-bottom: 8px;

  font-size: 30px;
  line-height: 38px;
}

.image {
  position: absolute;
  right: -24px;
  top: -24px;

  width: 177px;
  height: 120px;
}
