@import "src/styles/variables";

.cards {
  display: flex;
  flex-wrap: wrap;

  margin: calc(-1 * $grid_gap / 2);

  @media screen and (max-width: $breakpoint-small-mobile) {
    margin: calc(-1 * $grid_gap_mobile / 2);
  }
}

.title {
    margin-bottom: 20px;

    font-family: var(--font-aeroport);
    font-size: 22px;
    font-weight: 700;

    line-height: 24px;

    @media screen and (min-width: $breakpoint-tablet) {
        margin-bottom: 24px;
        font-size: 28px;

        line-height: 32px;
    }
}
