@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.longDebtContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 12px;
}

.content {
  align-items: center;
  justify-content: space-between;
}

.subtitles {
  align-items: center;

  > * {
    flex: 1;
  }

  margin-top: -4px;
}

.halfOfBlock {
  width: 60%;
}

.alignRight {
  text-align: right;
}

.badge {
  > span {
    border-radius: 4px;
    height: 20px;
  }
}

.decoratedText {
  text-decoration: line-through;
  text-decoration-thickness: 0.5px;
  color: var(--color-D30);
}
