.container {
  position: relative;
  width: 100%;

  [data-qa='TextInput'] {
    pointer-events: none;
    user-select: none;
  }
}

.slider {
  position: absolute;
  right: 16px;
  bottom: -7px;
  left: 16px;
}
