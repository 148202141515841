@import 'src/styles/variables';

.container {
  background-color: var(--color-L100);
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  gap: 16px;
  border-radius: 16px;
}

.amount {
  @media screen and (min-width: $breakpoint-desktop) {
    flex: 3;
  }
}

.button {
  width: 100%;
  flex: 1;

  @media screen and (max-width: $breakpoint-desktop) {
    flex: none;
  }
}

.heading {
  padding: 16px;
}

.content {
  display: flex;
  gap: 15px;

  @media screen and (max-width: $breakpoint-desktop) {
    flex-direction: column;
  }
}
